// 中文语言包
export default {
  action: {
    action: "操作",
    add:"添加",
    edit: "编辑",
    copy: "复制",
    delete: "删除",
    default: "默认值",
    complete: "添加注释",
    recover_from_this_verision: "恢复到此版本",
    add_verify_list: "添加到待审列表",
    save: "保存",
    verify: "审批",
    preview_pdf: "打印预览",
    back_home: "返回列表",
    recover_from_this_version:"恢复到此版本",
    upload_to_library:"上传到资料库",
    import_from_spec:"从 Spec 导入"
  },
  confirm:{
    tips:"温馨提示",
    confirm: "确认",
    cancel: "取消",
    ok:"确定",
    delete: "您确定要删除数据吗？",
    update: "您确定要更新数据吗？",
    copy:"您确定要复制该数据？",
    save_success:"保存成功",
    requested_by_empty:"请求者不能为空!",
    items_revised_empty:"修订说明不能为空的!",
    edited_by_empty:"编辑者不能为空!",
    reason_empty:"修订原因不能为空!",
    item_code_already_exists:"产品编号已存在",
    item_code_empty:"产品编号不能为空",
    item_code_no_audited_record:"没有 {{}}item_code 的已审核 spec 记录。请检查产品编码，或检查对应 spec 是否已审核。",
    save_fail:"保存失败",
    verify_tips:"确认审核？",
    verify_success:"审核成功",
    verify_fail:"审核失败",
    import_success:"导入成功",
    import_fail:"导入失败",
    success:"操作成功",
    no_verified_item_code:"当前的产品编码不存在已审核的 spec 记录",
    item_code_checking:"产品编码检查中",
    print_tips:"需要打印客户签名字段吗？",
    verify_print_tips:"批准人的签名要不要打印出来？",
    no_revision_data:"请输入修订记录"
  },
  list: {
    lot:"批号",
    lot_number: "批号",
    number: "序号",
    ty_number:"内销订单/样品编号",
    verify: "审核",
    item_code:"产品编号",
    product_name: "产品名称",
    product_description: "产品描述",
    product_type: "产品类型",
    sds_type: "SDS 类型",
    rev: "版本",
    create_by: "创建",
    create_time: "创建时间",
    update_by: "编辑人",
    update_time: "编辑时间",
    verified_name:"审核人",
    verified_by: "审核人",
    verified_time: "审核时间",
    china_lot:"内销订单号/样品编号",
    quantity:"数量",
    shipping_method:"送货方式",
    shipment_date:"送货日期",
  },
  view: {
    user_manager:"用户管理",
    login:"登录",
    login_out:"登出",
    detail_manager: "编辑栏",
    placeholder:"请输入",
    select_placeholder:"请选择",
    data_placeholder:"选择日期",
    search:"搜索",
    list:"列表",
  },
  content: {
    expiration_date:"截止日期",
    shelf_life_note:"未开封情况下${other_notes.shelflife_number}个月保质期。",
    add_user:"添加用户",
    user_name:"用户名",
    nick_name:"昵称",
    role:"角色",
    manager_user:"管理员",
    normal_user:"普通用户",
    read_only_user:"只读用户",
    phone:"手机号",
    reset_password:"重置密码",
    new_password:"新密码",
    confirm_password:"确认密码",
    us_user:"美国用户",
    ch_user:"中国用户",
    add_user_info:"添加用户信息",
    edit_user_info:"编辑用户信息",
    rev:"修订记录",
    revised_notes:"修订记录",
    po_number:"订单号",
    customer_code:"客户编码",
    custom:"客户",
    is_show_note:"是否显示备注",
    manufacuture_date:"生产日期",
    product_type: "产品类型",
    product_description: "产品描述",
    list: "中文列表",
    please_input: "请输入",
    lot: "批号",
    item_code: "产品编码",
    sds_type: "SDS类别",
    product_name: "产品名称",
    version_number: "版本号",
    customer_product_name: "客户定制产品名称",
    botanical_name: "植物学名",
    inci_name: "INCI名称",
    appearance: "外观",
    solubility: "溶解度",
    ph: "pH",
    specific_gravity: "比重",
    color: "颜色",
    odor: "气味",
    customs_classification: "海关分类",
    print_title: "编辑打印标题",
    print_please_verify: "打印客户签名栏",
    supplier_or_producer: "供应商/生产商",
    storage: "存放",
    composition_information_on_ingredients: "组份/成分说明",
    substance_name: "物料名称",
    cas_number: "CAS编号",
    einecs_number: "EINECS编号",
    r_phrase: "风险描述语",
    s_phrase: "安全描述语",
    base_info: "基础信息",
    processing: "加工工艺",
    physical_properties: "物理形态",
    confidential: "保密文件",
    custom_formula: "客户定制配方",
    document_notes: "文件备注",
    custom_field: "自定义字段",
    general_specification: "产品信息",
    ingredients: "成分",
    common_other_name: "通用名/其他名称",
    part_used: "使用部位",
    percentage: "百分比",
    custom_field_before_regular_data: "自定义字段",
    country_of_origin: "原产地",
    extraction_ratio: "提取率",
    major_constituents_in_native_herbs: "植物主要成分",
    important_constitue_in_plant: "植物主要成分",
    homogeneity: "均匀度",
    extraction_process: "提取过程",
    processing_aid: "加工助剂",
    solvent_for_extraction: "提取溶剂",
    solvent: "溶剂",
    carrier: "辅料",
    other_ingredients: "其他成分",
    pesticides: "农药残留",
    certification: "证书",
    preservative: "防腐剂",
    product_grade: "产品级别",
    analysis: "分析项目",
    standard1: "标准1",
    standard2: "标准2",
    standard3: "标准3",
    mesh_size: "过筛尺寸",
    loss_on_drying: "干燥失重",
    bulk_density: "堆密度",
    identification: "HPTLC鉴定",
    bioactives_level: "活性成分控制指标",
    moisture: "水分含量",
    specifica_gravity: "比重",
    refractive_index: "折光率",
    optical_rotation: "旋光度",
    acid_value: "酸值",
    peroxide_value: "过氧化值",
    saponification_value: "皂化值",
    unsaponifiable_master: "不皂化物含量",
    moisture_volatiles: "水分和挥发物含量",
    total_heavy_metals: "重金属",
    arsenic: "砷",
    cadmium: "镉",
    lead: "铅",
    mercury: "汞",
    taste: "味道",
    microbiological_tests: "微生物测试",
    total_plate_count: "细菌总数",
    yeast_and_mold: "酵母和霉菌",
    ecoli: "大肠杆菌",
    salmonella: "沙门氏菌",
    staph_aureus: "金色葡萄球菌",
    total_coliforms: "大肠菌群总数",
    fatty_acid: "脂肪酸",
    caprylic: "C8:0 辛酸",
    capric: "C10:0 癸酸",
    lauric: "C12:0 月桂酸",
    myristic: "C14:0 豆蔻酸",
    myristoleic: "C14:1 豆蔻油酸",
    palmitica: "C16:0 棕榈酸",
    palmitoleic: "C16:1 棕榈油酸",
    stearic: "C18:0 硬脂酸",
    oleic: "C18:1 油酸",
    linoleicacid: "C18:2 亚油酸",
    a_linolenic: "C18:3 α-亚麻酸",
    y_linoleic: "C18:3 γ-亚麻酸",
    punicicacid: "C18:3 石榴酸/Punicic acid",
    arachidic: "C20:0 花生酸/AA",
    arachidonic: "C20:4 花生四烯酸",
    eicosapentaenoic: "C20:5 二十碳五烯酸/EPA",
    docosahexaenoic: "C22:6 二十二碳六烯酸/DHA",
    composition: "成分组成",
    others: "其它",
    other_notes: "其他信息",
    notes:"备注",
    shelflife_number: "保质期(*个月)",
    shelf_life: "保质期备注",
    package_info: "包装",
    revision: "修订记录",
    approved_by: "批准人",
    edited_by: "修订人",
    items_revised: "修订项目",
    reason: "修订原因",
    requested_by: "提出修订人",
    revision_date: "修订日期",
    punicic_acid:"Punicic Acid",
    linoleic_acid:"Linoleic Acid",
    note1:"备注1",
    note2:"备注2",
    note3:"备注3",
  }
}
