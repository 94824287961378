import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n  from '../language/index'
Vue.use(VueRouter)

const routes = [{
        path: '/Powder',
        name: 'Powder',
        component: () =>
            import ( '../views/CoaModule/Powder.vue')
    },
    {
        path: '/home',
        name: 'Home',
        component: () =>
            import ( '../views/Home.vue')
    },
    {
        path: '/home2',
        name: 'Home2',
        component: () =>
            import ( '../views/NEW/module/Home.vue')
    },
    {
        path: '/home3',
        name: 'Home3',
        component: () =>
            import ( '../views/Home3.vue')
    },
    {
        path: '/spec',
        name: 'SpecItems',
        component: () =>
            import ( '../views/SpecItems.vue')
    },
    {
        path: '/sds',
        name: 'SdsItems',
        component: () =>
            import ( '../views/SdsItems.vue')
    },
    {
        path: '/composition',
        name: 'CompositionItems',
        component: () =>
            import ( '../views/CompositionItems.vue')
    },
    {
        path: '/tps',
        name: 'TpsItems',
        component: () =>
            import ( '../views/TpsItems.vue')
    },
    {
        path: '/cn-home',
        name: 'CnHome',
        component: () =>
            import ( '../views/CnHome.vue')
    },
    {
        path: '/cn-home2',
        name: 'CnHome2',
        component: () =>
            import ( '../views/CnHome2.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import ('../views/Login.vue')
    },
    {
        path: '/cn-login',
        name: 'CnLogin',
        component: () =>
            import ('../views/CnLogin.vue')
    },
    {
        path: '/new-item',
        name: 'NewItem',
        component: () =>
            import ('../views/NewItem.vue')
    },
    {
        path: '/new-item-sds',
        name: 'NewItemSds',
        component: () =>
            import ('../views/NewItemSds.vue')
    },
    {
        path: '/new-item-comp',
        name: 'NewItemComposition',
        component: () =>
            import ('../views/NewItemComposition.vue')
    },
    {
        path: '/new-item-conversion',
        name: 'NewItemConversion',
        component: () =>
            import ('../views/NewItemConversion.vue')
    },
    {
        path: '/new-item-tds',
        name: 'NewItemTds',
        component: () =>
            import ('../views/NewItemTds.vue')
    },
    {
        path: '/new-item-method',
        name: 'NewItemTestMethod',
        component: () =>
            import ('../views/NewItemTestMethod.vue')
    },
    {
        path: '/cn-item-conversion',
        name: 'NewItemConversion',
        component: () =>
            import ('../views/CnModule/NewItemConversion.vue')
    },
    {
        path: '/cn-item-tds',
        name: 'NewItemTds',
        component: () =>
            import ('../views/CnModule/NewItemTds.vue')
    },
    {
        path: '/cn-item-method',
        name: 'NewItemTestMethod',
        component: () =>
            import ('../views/CnModule/NewItemTestMethod.vue')
    },
    {
        path: '/print',
        name: 'Print',
        component: () =>
            import ('../views/Print.vue')
    },
    {
        path: '/coa-items',
        name: 'CoaItems',
        component: () =>
            import ('../views/CoaItems.vue')
    },
    {
        path: '/coa-item',
        name: 'CoaDetail',
        component: () =>
            import ('../views/CoaDetail.vue')
    },
    {
        path: '/',
        redirect: '/home',
    },
    {
        path: '/OilEhxto',
        name: 'OilEhxto',
        component: () =>
            import ('../views/CoaModule/OilEhxto.vue')
    },
    {
        path: '/cn-detail',
        name: 'CnDetail',
        component: () =>
            import ('../views/CnModule/CnDetail.vue')
    },
    {
        path: '/cn-detail-sds',
        name: 'CnDetailSds',
        component: () =>
            import ('../views/CnModule/CnDetailSds.vue')
    },
    {
        path: '/cn-label',
        name: 'label',
        component: () =>
            import ('../views/Label/label.vue')
    },
    {
        path: '/cn-safety',
        name: 'CnSafetyDetail',
        component: () =>
            import ('../views/CnModule/CnSafetyDetail.vue')
    },
    {
        path: '/cn-coa',
        name: 'CnCoa',
        component: () =>
            import ('../views/CnCoa.vue')
    },
    {
        path: '/cn-worklist',
        name: 'CnCoaWorkList',
        component: () =>
            import ('../views/CnCoaWorkList.vue')
    },
    {
        path: '/cn-coaDetail',
        name: 'CnCoaDetail',
        component: () =>
            import ('../views/CnModule/CnCoaDetail.vue')
    },
    {
        path: '/file',
        name: 'File',
        component: () =>
            import ('../views/File/File.vue')
    },
    {
        path: '/sofile',
        name: 'SoFile',
        component: () =>
            import ('../views/File/SoFile.vue')
    },
    {
        path: '/file-preview',
        name: 'FilePreview',
        component: () =>
            import ('../views/File/FilePreview.vue')
    },
    {
        path: '/library-preview',
        name: 'Library',
        component: () =>
            import ('../views/File/Library.vue')
    },
    {
        path: '/verify-list',
        name: 'VerifyList',
        component: () =>
            import ('../views/VerifyList.vue')
    },
    {
        path: '/verify-list2',
        name: 'VerifyList2',
        component: () =>
            import ('../views/VerifyList2.vue')
    },
    {
        path: '/cn-verifylist',
        name: 'CnVerifyList',
        component: () =>
            import ('../views/CnVerifyList.vue')
    },
    {
        path: '/no-pdf',
        name: 'NoPDF',
        component: () =>
            import ('../views/PrintProductTypeModule/NoPrintPage.vue')
    },
    {
        path: '/new-spec',
        name: 'NewSpec',
        component: () =>
            import ('../views/NEW/module/spec/NewSpec.vue')
    },
    {
        path: '/cn-new-spec',
        name: 'CNNewSpec',
        component: () =>
            import ('../views/NEW/module/spec/CnNewSpec.vue')
    },
    {
        path: '/erp-preview',
        name: 'erp-preview',
        component: () =>
            import ('../views/NEW/module/spec/ERP-PreviewPDF.vue')
    },
    {
        path: '/new-sds',
        name: 'new-sds',
        component: () =>
            import ('../views/NEW/module/sds/NewSDS.vue')
    },
    {
        path: '/new-coa',
        name: 'new-coa',
        component: () =>
            import ('../views/NEW/module/coa/CoaList.vue')
    },
    {
        path: '/new-coa-detail',
        name: 'new-coa-detail',
        component: () =>
            import ('../views/NEW/module/coa/CoaDetail.vue')
    },
    {
        path: '/detail',
        name: 'panel-detail',
        component: () =>
            import ('../views/NEW/module/coa/PanelDetail.vue')
    },
    {
        path: '/new-verify-list',
        name: 'new-verify-list',
        component: () =>
            import ('../views/NEW/module/NewVerifyList.vue')
    },
    {
        path: '/user-list',
        name: 'user-list',
        component: () =>
            import ('../views/UserManager/UserList.vue')
    },
    {
        path: '/email-test',
        name: 'email-test',
        component: () =>
            import ('../views/NEW/module/coa/email-trigger/EmailTriggerTest.vue')
    }
]

const router = new VueRouter({
    routes
})

function setupLanguage(routeParams) {
    const currentLanguage = localStorage.getItem('language') || 'en';
    // console.info('当前语言:', currentLanguage);

    if (routeParams.language) {
        const newLanguage = routeParams.language.toLowerCase();
        console.info('新语言:', newLanguage);
        console.info('语言变更:',newLanguage);

        i18n.locale = newLanguage === 'cn' || newLanguage === 'zh' ? 'zh' : 'en';
        localStorage.setItem('language', i18n.locale);
    } else if (currentLanguage !== 'en') {
        i18n.locale = 'en';
        localStorage.setItem('language', 'en');
        console.info('语言设定为：英文');
    }
}


router.beforeEach((to, from, next) => {
    // 语言设定
    const routeParams = to.query;
    setupLanguage(routeParams);

    const Token = localStorage.getItem('specToken')
    if (to.path == '/login' || 
        to.path == '/cn-login' || 
        to.path.indexOf("/file") > -1 || 
        to.path.indexOf("/sofile") > -1 || 
        to.path.indexOf("/library-preview") > -1 || 
        to.path.indexOf("/new-item") > -1 || 
        to.path.indexOf("/coa-item") > -1 ||
        to.path.indexOf("/erp-preview") > -1 
    ) {
        next();
    } else {
        if (Token) {
            localStorage.setItem("login_redirect",to.fullPath)
            next();
        } else {
            if (window.location.hostname.includes('cn.spec')){
                next({ 
                    path: '/cn-login', //跳转登录页
                    query: {
                        redirect: "/cn-home" //记录当前是从哪里跳过去的   在登录后直接返回原来的页面
                    }
                });
            }else {
                next({ 
                    path: "/login", //跳转登录页
                    query: {
                        redirect: to.fullPath //记录当前是从哪里跳过去的   在登录后直接返回原来的页面
                    }
                });
            }
        }
    }

});

export default router